<template>
  <div class="lg:flex lg:space-x-10">
    <div class="w-full space-y-7">
      <div class="card p-4">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ ticket.title }}</h1>
        
        <div class="flex items-center space-x-3 my-5 pb-4 border-b">
          <router-link v-if="ticket.author" :to="{name: 'UserDetail', params: {id: ticket.author.id}}">
            <img :alt="ticket.author.username" :src="ticket.author.avatar ? ticket.author.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'" class="w-10 h-10 rounded-full object-cover">
          </router-link>
          <img v-if="!ticket.author" src="https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg" alt="" class="w-10 h-10 rounded-full object-cover">
          <div>
            <div class="text-base font-semibold">
              <router-link v-if="ticket.author" :to="{name: 'UserDetail', params: {id: ticket.author.id}}">
                {{ ticket.author.username }}
              </router-link>
            </div>
            <div class="text-xs">{{ timeago(ticket.created_at) }}</div>
          </div>
          <div>
            <router-link :to="{name: 'TicketEdit', params: {id: id}}" v-if="isAuthor" class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-green-600 text-white space-x-1.5">
              <i class="uil-pen mr-1"></i>Sửa
            </router-link>
          </div>
        </div>
        
        <p class="mt-4">Danh mục: {{ getTicketCategory(ticket.category) }}</p>
        
        <div class="space-y-3 content mt-4" v-html="ticket.content"></div>
      </div>

      <comment :allow-comment="ticket.status !== 'closed'" object-type="ticket" :comment-id="commentId" :show-more="false" :object-id="id" field="id" type="ID"/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {getTicketCategory, logActivity, timeago} from "../../core/services/utils.service";
import Comment from "../comment/Comment";

export default {
  name: "TicketDetail",
  components: {
    Comment
  },
  data() {
    return {
      id: 0,
      commentId: null,
      ticket: {
        title: "",
        content: "",
        author_name: "",
        is_closed: 0,
        created_at: ""
      }
    }
  },
  methods: {
    loadTicket() {
      let query = `query($id: ID!) {
        ticket(id: $id) {
          id
          title
          content
          created_at
          status
          category
          author {
            id
            username
            avatar {
              url
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.ticket) {
              this.ticket = data.data.ticket;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    reRender() {
      this.loadTicket();
      logActivity("view", "ticket", this.id);
    },
    timeago(x) {
      return timeago(x);
    },
    getTicketCategory(x) {
      return getTicketCategory(x);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
